import { BeyondFashionBannerDto } from 'types/dtos'
import { BeyondFashionBannerModel } from 'types/models'

export const transformBeyondFashionBanner = ({
  name,
  image_url,
  dark_image_url,
  title,
  body,
  delay_in_minutes,
  ab_test,
  actions,
}: BeyondFashionBannerDto): BeyondFashionBannerModel => ({
  name,
  imageUrl: image_url,
  darkImageUrl: dark_image_url,
  title,
  body,
  delayInMinutes: delay_in_minutes,
  abTest: ab_test,
  actions,
})
